@import '../../styles/global';

.login-form {
  p {
    font-size: 0.875rem;
    flex-shrink: 0;
  }

  .extras-row {
    margin-top: 2rem;
  }

  .line-flourish {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    pointer-events: none;
    opacity: 0;

    div {
      width: 32px;
      border-bottom: solid 2px var(--color-gray-200);
    }

    @media (min-width: 768px) {
      opacity: 1;
    }
  }

  .remember {
    display: flex;
    color: var(--primary);
    transition: all 300ms;
    cursor: pointer;
    transform-origin: 0 50%;

    input {
      opacity: 0;
      pointer-events: none;
      position: absolute;
      top: 0;
      left: 0;
    }

    &:hover {
      transform: scale(1.05);
      color: var(--secondary);
    }
  }

  .forgot {
    color: var(--primary);
    transition: all 300ms;
    cursor: pointer;
    transform-origin: 100% 50%;
    margin-bottom: -1px;

    &:hover {
      transform: scale(1.05);
      color: var(--secondary);
    }
  }

  &.login-error {
    .line-flourish {
      color: var(--color-gray-500);
    }

    .remember {
      color: var(--color-red-700);

      .remember-text {
        color: var(--color-gray-500);
      }
    }

    .forgot {
      color: var(--color-red-700);
      font-weight: bold;
    }

    .forgot:hover {
      transform: scale(1.05);
    }

    .form-error {
      opacity: 1;
    }
  }

  &.validation-error {
    .remember {
      color: var(--color-gray-500);
    }

    .forgot {
      color: var(--color-gray-500);
    }
  }
}
