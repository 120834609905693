.loading {
  @apply flex justify-center items-center w-full min-h-full opacity-50 flex-shrink-0;
}

svg {
  @apply flex-shrink-0;
}

.tri-1,
.tri-2,
.tri-3 {
  perspective: 500px;
  position: relative;
  transform-style: flat;
  transform-origin: 47.7px 47.7px;
  transition: 0.25s;
}

.tri-1 {
  fill: var(--tertiary);
}

.tri-2 {
  fill: var(--secondary);
}

.tri-3 {
  fill: var(--primary);
}

/*Triangle 1, top light blue*/

.frame-1 .tri-1 {
  transform: rotate3d(1, 1, 0, -180deg);
}

.frame-2 .tri-1 {
  transform: rotate3d(0, 0, -1, 180deg);
}

.frame-3 .tri-1 {
  transform: rotate3d(-1, 1, 0, 180deg);
}

/*Triangle 2, bottom medium blue*/
.frame-1 .tri-2 {
  transform: rotate3d(1, 1, 0, 180deg);
}

.frame-2 .tri-2 {
  transform: rotate3d(0, 0, 1, -180deg);
}

.frame-3 .tri-2 {
  transform: rotate3d(-1, 1, 0, -180deg);
}

/*Triangle 3, middle dark blue*/

.frame-1 .tri-3 {
  transform: rotate3d(-1, 1, 0, 180deg);
}

.frame-2 .tri-3 {
  transform: rotate3d(0, 0, 1, 180deg);
}

.frame-3 .tri-3 {
  transform: rotate3d(1, 1, 0, 180deg);
}
