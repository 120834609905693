.flipper {
  transition: transform 300ms;
  transform-style: preserve-3d;
  position: relative;

  & > * {
    backface-visibility: hidden;
    position: absolute;
    transform: rotateX(0deg);
  }

  &.flip {
    transform: rotateY(180deg) rotateX(0deg);
  }
}
