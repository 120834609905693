@mixin row {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.row {
  @include row;
}

.spaced-row {
  @include row;
  justify-content: space-between;
}
