@import '../../styles/global';

.input-container {
  .password-input {
    .input-row {
      border-bottom-style: solid;
      border-bottom-color: var(--secondary);

      &:focus-within {
        border-bottom-width: 4px;
        border-bottom-color: var(--tertiary);
      }
    }

    &.valid {
      #password-length-hint {
        color: green;
        &::after {
          content: ' ✓';
        }
      }
    }

    &.unmet {
      .input-row {
        border-bottom-color: var(--color-red-700);

        label {
          color: var(--color-red-700);
        }
      }
    }

    #password-length-hint {
      color: var(--color-gray-500);
      font-size: 0.75rem;
    }
  }
}
