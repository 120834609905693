@import '../../styles/global';
@import '../../styles/row';

.selector {
  width: 100%;
  padding-top: calc(1rem - 2px);

  label {
    color: var(--primary);
    position: absolute;
    display: inline-flex;
    transition: all 300ms;
    z-index: 0;
    transform-origin: 0 0;
    transform: translate(0px, -12px) scale(0.75);
  }

  &.error,
  &.required {
    label {
      color: var(--color-red-700);
      opacity: 0.6;
    }

    .selector-input-row {
      border-bottom: solid 2px var(--color-red-700);
    }

    .selector-input-row:focus-within {
      border-bottom: solid 4px var(--color-red-700);
    }
  }

  .selector-input-row {
    @include row;
    min-height: 40px;
    position: relative;
    border-bottom: solid 2px var(--secondary);
    padding-bottom: 2px;

    &:focus-within {
      border-bottom: solid 4px var(--tertiary);
      padding-bottom: 0;
    }
  }

  svg {
    width: 20px;
    height: 20px;
  }
}

.input-container.disabled {
  .selector-input-row {
    border-bottom: solid 2px var(--color-gray-300);
    color: var(--color-gray-500);
    pointer-events: none;
  }
}

.loading-selector {
  height: 54px;
  width: 100%;
}
