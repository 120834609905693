@import '../../styles/global';
@import '../../styles/media';

$offWhite: #f4f4f4;
$animDur: 100ms;
$imgSize: 100px;

.card {
  display: flex;
  flex-direction: column;
  width: 100%;
  position: relative;
  border-radius: 0.5rem;
  cursor: pointer;
  transition: all $animDur;
  transition-timing-function: ease-in-out;
  background: white;
  overflow: hidden;

  @media (hover: hover) and (pointer: fine) {
    &:hover {
      color: var(--secondary);
      background: $offWhite;
      transform: scale(1.02);
      svg {
        opacity: 1;
      }
    }
  }

  .title {
    font-size: 0.875rem;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
  }

  .description {
    font-size: 0.75rem;

    h1 {
      font-size: 1.25rem;
    }
    h2 {
      font-size: 1.125rem;
    }
    h3 {
      font-size: 1rem;
    }
  }

  @include media('>=md') {
    .title {
      font-size: 1rem;
    }

    .description {
      font-size: 0.875rem;
    }
  }

  & + .card {
    margin-top: 1rem;
  }

  &.selected {
    color: var(--primary);
  }

  &.searched {
    color: var(--secondary);
    box-shadow: 0 0 10px 10px var(--secondary-soft);
  }

  .svg-container {
    flex: 0 0 64px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--color-gray-500);

    svg {
      width: 2rem;
      height: 2rem;
    }
  }

  .card-text {
    flex: 1 1 0;
    padding: 1rem;
    //hyphens: auto;
    transition: all $animDur ease-in-out;
    width: 100%;

    .description {
      color: var(--color-gray-500);
      display: -webkit-box;
      -webkit-line-clamp: 10;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }

    &.with-image {
      max-width: calc(100% - #{$imgSize});
    }
  }

  .card-badges {
    display: inline-flex;
    margin-right: 0.5rem;
  }
}

.card-badge {
  color: white;
  border-radius: 1rem;
  border-width: 1px;
  font-weight: bold;
  font-size: 0.5rem;
  padding: 0.125rem 0.375rem;
  margin-right: 0.25rem;
  height: 18px;
}
