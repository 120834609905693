@import '../../styles/global';
@import '../../styles/row';

.input-container {
  position: relative;
  display: flex;
  width: 100%;

  ::selection {
    background: var(--tertiary-soft);
  }

  @keyframes onAutoFillStart {
  }

  @keyframes onAutoFillCancel {
  }

  input {
    z-index: 1;
    background: transparent;
    width: 100%;
    outline: none;

    &:-webkit-autofill {
      animation-name: onAutoFillStart;
    }

    &:not(:-webkit-autofill) {
      animation-name: onAutoFillCancel;
    }
  }

  label {
    top: 24px;
    position: absolute;
    z-index: 0;
    display: inline-flex;
    color: var(--color-gray-500);
    transition: all 300ms;
    transform-origin: 0 0;
    transform: translateY(2px);
  }

  svg {
    width: 1rem;
    height: 21px;
    flex-shrink: 0;
  }

  label.touched {
    color: var(--primary);
    transform: translate(0px, -16px) scale(0.75);
    padding: 0.125rem 0.25rem;
    margin: -0.125rem -0.25rem;
  }

  label.touched.no-offset {
    transform: translate(0px, -32px) scale(0.75);
  }

  &.error,
  & .error {
    label {
      color: var(--color-red-700);
    }

    .input-row {
      border-bottom: solid 2px var(--color-red-700);
    }

    .input-row:focus-within {
      border-bottom: solid 4px var(--color-red-700);
    }
  }

  &.disabled {
    label {
      color: var(--color-gray-500);
    }

    input {
      cursor: not-allowed;
      color: var(--color-gray-500);
    }

    .input-row {
      border-bottom: solid 2px var(--color-gray-300);
    }
  }

  .input-row {
    height: 54px;
  }

  .input-row,
  .input-row-tall {
    @include row;
    padding-bottom: 0.25rem;
    width: 100%;
    padding-top: 18px;
    align-items: flex-end;
    min-height: 54px;

    // Make border thicker when focused, adjust margin to keep everything stable
    border-bottom: solid 2px var(--secondary);

    &:focus-within {
      border-bottom: solid 4px var(--tertiary);
      padding-bottom: calc(0.25rem - 2px);
    }
  }

  .input-row-tall {
  }

  &.dirty {
    label.can-be-dirtied {
      background: var(--tertiary-soft);
      border-radius: 0.375rem;
    }
  }
}
