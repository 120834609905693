.list-body {
  @apply flex flex-col flex-1 p-4;
}

.ptr {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
}

.reduced-ptr-height .ptr {
  height: calc(100% - 48px);
}
