.duration-input {
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
  width: 100%;

  &.error #hint {
    color: var(--color-red-700);
  }
}
