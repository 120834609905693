@import '../../styles/global';
@import '../../styles/shadow';

.khh-button {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5rem;
  border-radius: 0.375rem;
  border: 1px solid transparent;
  transform: scale(1);
  transition: all 300ms;
  cursor: pointer;

  &.allowed:hover {
    transform: scale(1.02);
  }

  &.not-allowed {
    cursor: not-allowed;
  }

  &:disabled {
    opacity: 0.7;
  }

  &.pane-button {
    @include shadow;
    background-image: linear-gradient(
        to bottom,
        var(--color-gray-400) 0%,
        var(--color-gray-400) 35%,
        transparent 60%,
        transparent
      ),
      linear-gradient(-15deg, var(--primary), var(--tertiary));
    background-size: 105% 400%;
    width: 100%;
    color: white;
    font-size: 1rem;
    background-position: 2.5% 5%;

    &:enabled {
      background-position: 2.5% 80%;
    }

    &.error {
      background-image: linear-gradient(
        -10deg,
        var(--color-red-900),
        var(--color-red-500)
      );
    }
  }

  & .loader {
    width: 1rem;
    height: 1rem;
    border-radius: 50%;
    position: relative;
    text-indent: -9999em;
    animation: khh-button-loader 1.3s infinite linear;
    transform: translateZ(0) scale(0.2);
  }

  &.primary {
    @include shadow;
    color: white;
    background: var(--primary);

    &.destructive {
      &.allowed:hover {
        background: var(--color-red-500);
      }

      &:not([disabled]) {
        color: white;
        background: var(--color-red-500);
      }
    }

    &:disabled {
      background: var(--color-gray-500);
    }

    &.allowed:hover {
      background: var(--secondary);
    }
  }

  &.secondary {
    @include shadow;
    color: var(--primary);
    background-color: transparent;

    &:disabled {
      color: var(--color-gray-500);
    }

    &.allowed:hover {
      color: var(--secondary);
      background-color: var(--color-gray-50);
    }

    &.destructive {
      &:hover {
        background: var(--color-red-50);
      }
      &:not([disabled]) {
        color: var(--color-red-500);
      }
    }
  }

  &.tertiary {
    color: var(--color-gray-500);
    background-color: transparent;

    &.allowed:hover {
      @include shadow;
      color: black;
      background-color: var(--color-gray-50);
    }

    &.destructive {
      &:hover {
        background: var(--color-red-50);
      }
      &:not([disabled]) {
        color: var(--color-red-500);
      }
    }
  }
}

@keyframes khh-button-loader {
  0%,
  100% {
    box-shadow: 0 -3em 0 0.2em, 2em -2em 0 0em, 3em 0 0 -1em, 2em 2em 0 -1em,
      0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 0;
  }
  12.5% {
    box-shadow: 0 -3em 0 0, 2em -2em 0 0.2em, 3em 0 0 0, 2em 2em 0 -1em,
      0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 -1em;
  }
  25% {
    box-shadow: 0 -3em 0 -0.5em, 2em -2em 0 0, 3em 0 0 0.2em, 2em 2em 0 0,
      0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 -1em;
  }
  37.5% {
    box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0em 0 0, 2em 2em 0 0.2em,
      0 3em 0 0em, -2em 2em 0 -1em, -3em 0em 0 -1em, -2em -2em 0 -1em;
  }
  50% {
    box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 0em,
      0 3em 0 0.2em, -2em 2em 0 0, -3em 0em 0 -1em, -2em -2em 0 -1em;
  }
  62.5% {
    box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 -1em,
      0 3em 0 0, -2em 2em 0 0.2em, -3em 0 0 0, -2em -2em 0 -1em;
  }
  75% {
    box-shadow: 0em -3em 0 -1em, 2em -2em 0 -1em, 3em 0em 0 -1em, 2em 2em 0 -1em,
      0 3em 0 -1em, -2em 2em 0 0, -3em 0em 0 0.2em, -2em -2em 0 0;
  }
  87.5% {
    box-shadow: 0em -3em 0 0, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 -1em,
      0 3em 0 -1em, -2em 2em 0 0, -3em 0em 0 0, -2em -2em 0 0.2em;
  }
}
