.loading-card {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 1rem;
  border-radius: 0.5rem;
}

.loading-card-text {
  padding: 1rem;
  width: 100%;
}

.loading-card-line {
  @apply flex h-5;
  @apply rounded-sm;
  width: 100%;
  max-width: var(--line-width);
}

.shimmer {
  animation: shimmer 1.5s infinite;
  background: linear-gradient(to right, #e2e2e2 0%, #e2e2e2 50%, #eff1f3 100%);
  background-size: 1000px 100%;
}

@keyframes shimmer {
  0% {
    background-position: 0 0;
  }
  100% {
    background-position: 1000px;
  }
}
