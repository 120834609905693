.learner-dashboard-error {
  @apply space-y-8 opacity-40 text-xl;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.bug-report {
  @apply font-light mt-1 opacity-50 hover:opacity-100 cursor-pointer;
  @apply transform transition duration-300;
  @apply scale-100 hover:scale-105;
}

.error-id {
  margin-top: 2rem;
  font-size: 0.75rem;
  opacity: 0.5;
}

.bug-report u {
  transition: color 300ms;
}

.bug-report:hover u {
  color: var(--primary);
}

.exclamation {
  width: 5rem;
  height: 5rem;
  color: var(--color-gray-500);
}
