.boolean-input {
  display: flex;
  color: var(--primary);
  transition: all 300ms;
  cursor: pointer;
  transform-origin: 0 50%;

  input {
    opacity: 0;
    pointer-events: none;
    position: absolute;
    top: 0;
    left: 0;
    margin-right: 0.5rem;
  }

  .label-text {
    font-size: 0.875rem;
  }

  .flipper svg {
    height: 16px;
  }

  .flipper {
    margin-right: 0.5rem;
  }
}

.input-container {
  &.enabled {
    .boolean-input:hover {
      transform: scale(1.05);
      color: var(--secondary);
    }
  }

  &.disabled {
    .boolean-input {
      color: var(--color-gray-500);
      cursor: not-allowed;
    }
  }

  &.error {
    .boolean-input {
      color: var(--color-red-700);
    }
  }
}
